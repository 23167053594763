



.bg-lime-600 {
    background-color: rgb(101 163 13);
}


.bg-orange-500 {
    background-color: rgb(249 115 22);
}

.plan-btn {
    position: relative;
    max-width: 150px;
    flex: 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-right: 30px;
    margin-bottom: 30px;
}

.plan-btn span {
    position: absolute;
    top: 100%;
    font-weight: 800;
    font-size: 18px;
    line-height: 48px;
    color: black;
}

.plan-btn:last-child {
    margin-right: 0;
}

.plan-btn img {
    flex: 1;
    max-width: 75%;
    height: auto;
}