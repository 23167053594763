@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
html, body, #root, #root>div {
  width:width 100%;
  height: 100%;
  top: 0;
  left:0;
  font: serif;
  overflow: auto;
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, #root, #root>div {
    font-family: 'Open Sans', serif;
}

.gray-background{
 background-color: #778899;  /*background-image: linear-gradient(90deg, #4299e1 50%, #5dadf0 80%);*/ 
}

.gray-btn{
  border-radius: 3px;
  padding:12px 20px;
  color:white;
  text-transform: uppercase;
  font-size: 13px;
  font-weight:600;  
  letter-spacing: 1.2px;
  box-shadow: 0px 10px 10px -7px #0b3a7820;
  background-color: #778899;  /*background-image: linear-gradient(90deg, #4299e1 50%, #5dadf0 80%);*/
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .4s ease-out;
}

.logoThumb {
    width: 100px;
    height: 100px;
    margin: 5px;
    padding:5px;
    background-position: center center;
    background-size: cover;
}

.menu-item-box {
    ;
}
.menu-item-box:hover {
background-color: #f7faff;
}
.menu-item-box-a {
background-image: linear-gradient(105deg, #4299e1, #6fbdfe);
}
.bg-gradient {
background-image: linear-gradient(105deg, #4299e1, #6fbdfe);    
}
.bg-light {
    background: #f7faff
}

.menu-item {
    text-align: left;
    width: 100%;
    padding-left: 15px;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1.3px;
}
.submenu-item {
    text-align:left;
    padding-left:57px;
    padding-top:5px;
    padding-bottom:5px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.8px;
}

.submenu-item a:hover {
color: #4299e1 !important;
}
.nav-icon .MuiSvgIcon-root{
    opacity:0.7;
    font-size:1.2rem;
    padding-bottom:1px;
}
.nav-sidebar {
    width:300px;
    box-shadow: 0px 0px 7px #0b3a7820;
    z-index: 4444;
    border:0px;
}
.bg-gray-100 {
    background:#eff5fe;
}

.card-highlight {
    background-image: linear-gradient(105deg, #4299e1, #6fbdfe);
    border: 0px;
    box-shadow: 0px 3px 12px -3px #0b3a7820;
    padding:25px;
    border-radius:3px;
}
.card {
    background:white;
    border: 0px;
    box-shadow: 0px 10px 10px -7px #0b3a7820;
    padding:25px;
    border-radius:3px;
}

.card-video {
    background:white;
    height: 100%;
    border: 0px;
    box-shadow: 0px 10px 10px -7px #0b3a7820;
    padding:25px;
    border-radius:3px;
}

.marketing{
    width: 30%;
}
.tiny-card {
    background:white;
    border: 0px;
    box-shadow: 0px 10px 10px -7px #0b3a7820;
    padding:5px;
    border-radius:3px;
}
.submenu-box {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #f7faff;
}
.c-dropdown {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.2px;
    font-weight:500;
}
.highlight-h {
    text-transform: ;
    font-size: 15px;
    color:white;
    opacity:1;
    letter-spacing: 0px;
    font-weight:500;    
}

.highlight-desc {
    text-transform: uppercase;
    font-size: 13px;
    color:#ffffff;
    opacity:0.8;
    letter-spacing: 1.2px;
    font-weight:500;        
}
.highlight-figs {
    text-transform: uppercase;
    font-size: 32px;
    color:white;
    letter-spacing: 1.2px;
    font-weight:900;    
}


.card-h {
    text-transform: uppercase;
    font-size: 13px;
    color: #4a5568;
    padding-bottom:5px;
    letter-spacing: 1.2px;
    font-weight:600;    
}

.table-h {
    font-size: 16px;
    color: #4a5568;
    font-weight:600;    
}

.table-h button {
    position: relative;
    padding-right: 25px;
}

.table-h button svg {
    position: absolute;
    top: 0;
    right: 0;
}

.table-content {
    color: #4a5568;
    font-size: 16px;
}
.table-icon {
    color: #b7c6d2;
}
.mr-table{
    margin-right:1.5rem;
}

.table-icon:hover {
    color: #4299e1;
}



/* Date */
#admin-payments-table tr th:nth-child(1),
#admin-payments-table tr td:nth-child(1) {
    min-width: 150px;
}

/* Office */
#admin-payments-table tr th:nth-child(2),
#admin-payments-table tr td:nth-child(2) {
    min-width: 200px;
}

/* Description */
#admin-payments-table tr th:nth-child(3),
#admin-payments-table tr td:nth-child(3)
{
    /* display: block; */
    min-width: 250px;
    /* column-span: 8; */
}

#admin-payments-table tr th:nth-child(5),
#admin-payments-table tr td:nth-child(5) {
    min-width: 200px;
}



.small-interval {
}

.card-desc {
    text-transform: uppercase;
    font-size: 10px;
    color:white;
    letter-spacing: 1.2px;
    font-weight:600;    
    margin-left:8px;
    bottom:23px;
    border-radius:50px;
    background-image: linear-gradient(105deg, #4299e1, #6fbdfe);    
    padding: 3px 10px;
    position: relative;

}

.card-figs {
    text-transform: uppercase;
    font-size: 36px;
    letter-spacing: 1.2px;
    font-weight:900;
    color:#4299e1;
    margin-top:10px;    
}


.interval {
    text-transform: uppercase;
    font-size: 13px;
    color: #4a5568;
    opacity: 0.4;
    letter-spacing: 1.2px;
    font-weight:600;    
    padding: 10px 20px;
}

.interval-active {
    text-transform: uppercase;
    font-size: 13px;
    color: #4a5568;
    background:white;
    padding: 10px 20px;
    font-weight:600;    
    letter-spacing: 1.2px;
}
button:focus {
    outline:none !important
}

.bs-b {
    box-shadow: 0px 0px 7px #0b3a7820;
}

.stream-icon-mc {
    background:#4299e125;
    padding:3px;
    border-radius:55px;
    color:#4299e1;
    margin-top:19px;    
}
.stream-icon-md {
    background:#e1424225;
    padding:3px;
    border-radius:55px;
    color:#e1424290;
    margin-top:19px;

}
.stream-icon-pc {
    background:#42e16725;
    padding:3px;
    border-radius:55px;
    color:#42e167;
    margin-top:19px;
}
.stream-desc {
    font-size: 14px;
}
.stream-h {
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom:5px;
    letter-spacing: 1.2px;
    font-weight:600;
    opacity:0.4;    
    display:block;
}


.stream-timestamp {
    font-size: 11px;
    opacity:0.4;
    padding-top:5px;
    font-style: italic;
    font-weight:600;    
    display:block;
}

.scrollbar-custom::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scrollbar-custom::-webkit-scrollbar-track {
  background: #00000010; 
}
 
/* Handle */
.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #4299e1;   border-radius:5px !important;
}


.gradient-btn{
  border-radius: 3px;
  padding:12px 20px;
  color:white;
  text-transform: uppercase;
  font-size: 13px;
  font-weight:600;  
  letter-spacing: 1.2px;
  box-shadow: 0px 10px 10px -7px #0b3a7820;
  background-image: linear-gradient(90deg,#2a6bb0 70%,#5dadf0 100%);
  /*background-image: linear-gradient(90deg, #4299e1 50%, #5dadf0 80%);*/
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background .4s ease-out;
}

.white-btn, .blue-btn {
  border-radius: 3px;
  color: linear-gradient(90deg, #4299e1 50%, #5dadf0 80%);
  padding:12px 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight:600;  
  letter-spacing: 1.2px;
  box-shadow: 0px 10px 10px -7px #0b3a7820;

  background: white;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .4s ease-out;
}

.blue-btn {
    background: #2A6BB0;
}

.ch1 {
    font-size:24px;
    color: #4a5568;
}

.gradient-btn:hover {
  background-position: left bottom;
}

.profile-mi {
    text-transform: uppercase;
    font-size: 13px;
    color: #4a5568;
    letter-spacing: 1.2px;
    font-weight:600;    
}
.profile-dropdown {
    padding:20px;
    background:white;
    box-shadow: 0px 6px 10px -5px #0b3a7840;
    position: absolute;
    right: 23px;
    top: 30px;
    border-radius: 3px;
}

.office-dropdown {
    padding: 20px;
    background:white;
    box-shadow: 0px 6px 10px -5px #0b3a7840;
    position: absolute;
    right: 23px;
    top: 55px;
    border-radius: 3px;
    max-height: 30%;
}

.office-dropdown .inner {
    overflow-y: scroll;
}

.office-dropdown .inner button {
    display: block;
    width: 100%;
    padding: 5px 0;
}

.office-dropdown .inner button:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.profile-banner {
    text-align: right;
}

.profile-banner button {
    text-align: left;
    color: #2a6bb0;
    font-weight: 600;
}



/** Payments **/
.payments-status-failed-col .gradient-btn {
    padding: 8px 20px !important;
    margin-top: 5px;
}




.bg-lime-600 {
    background-color: rgb(101 163 13);
}


.bg-orange-500 {
    background-color: rgb(249 115 22);
}

.plan-btn {
    position: relative;
    max-width: 150px;
    flex: 1 1;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-right: 30px;
    margin-bottom: 30px;
}

.plan-btn span {
    position: absolute;
    top: 100%;
    font-weight: 800;
    font-size: 18px;
    line-height: 48px;
    color: black;
}

.plan-btn:last-child {
    margin-right: 0;
}

.plan-btn img {
    flex: 1 1;
    max-width: 75%;
    height: auto;
}
#login {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background: linear-gradient(-135deg,#BAD1BA,#3e5e3e);

    padding: 16px 0;

    overflow-y: scroll;
}

#login .container {
    /* max-width: 960px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto; */

    background-color: white;
    border-radius: 18px;
    padding: 177px 130px 33px 95px;
}

#login .container {
    max-width: 1024px;
}
#login .container {
    width: 100%;
    margin-top: 2%;
}

.login100-pic {
    width: 316px;

    will-change: transform; 
    transform: perspective(300px) rotateX(0deg) rotateY(0deg);
}

.login100-pic img {
    max-width: 100%;
    padding: 25px 0;
}


.side-img {
    width: 35%;
    height: auto;
}

#login .container .card {
    /* width: 50%; */
}


.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0 0;
    color: rgba(87,184,70,.8);
}

.input100:focus+.focus-input100+.symbol-input100 {
    color: #57b846;
    padding-left: 28px;
}

.symbol-input100 {
    font-size: 15px;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 35px;
    pointer-events: none;
    color: #666;
    transition: all .4s;
}

.container-login100-form-btn {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}

.login100-form-btn {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #929496;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    transition: all .4s;
}

.login100-form-btn:hover {
    background: #3e5e3e;
}

.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}

.input100 {
    font-family: Poppins-Medium;
    font-size: 15px;
    line-height: 1.5;
    color: #666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 68px;
}




.txt2 {
    font-family: Poppins-Regular;
    font-size: 20px;
    line-height: 1.5;
    color: #666;
    margin-left: 4px;
}

.p-t-136 {
    padding-top: 136px;
    }
