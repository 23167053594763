#login {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background: -webkit-linear-gradient(-135deg,#BAD1BA,#3e5e3e);
    background: -o-linear-gradient(-135deg,#BAD1BA,#3e5e3e);
    background: -moz-linear-gradient(-135deg,#BAD1BA,#3e5e3e);
    background: linear-gradient(-135deg,#BAD1BA,#3e5e3e);

    padding: 16px 0;

    overflow-y: scroll;
}

#login .container {
    /* max-width: 960px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto; */

    background-color: white;
    border-radius: 18px;
    padding: 177px 130px 33px 95px;
}

#login .container {
    max-width: 1024px;
}
#login .container {
    width: 100%;
    margin-top: 2%;
}

.login100-pic {
    width: 316px;

    will-change: transform; 
    transform: perspective(300px) rotateX(0deg) rotateY(0deg);
}

.login100-pic img {
    max-width: 100%;
    padding: 25px 0;
}


.side-img {
    width: 35%;
    height: auto;
}

#login .container .card {
    /* width: 50%; */
}


.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0 0;
    color: rgba(87,184,70,.8);
}

.input100:focus+.focus-input100+.symbol-input100 {
    color: #57b846;
    padding-left: 28px;
}

.symbol-input100 {
    font-size: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 35px;
    pointer-events: none;
    color: #666;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}

.login100-form-btn {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #929496;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}

.login100-form-btn:hover {
    background: #3e5e3e;
}

.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}

.input100 {
    font-family: Poppins-Medium;
    font-size: 15px;
    line-height: 1.5;
    color: #666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 68px;
}




.txt2 {
    font-family: Poppins-Regular;
    font-size: 20px;
    line-height: 1.5;
    color: #666;
    margin-left: 4px;
}

.p-t-136 {
    padding-top: 136px;
    }